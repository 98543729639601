<template>
  <!-- 課程相關資訊 -->
  <article class="cs-nav col-12 mt-6 mt-lg-8" data-style="2">
    <ul class="nav nav-pills nav__bar" id="pills-tab" role="tablist">
      <li class="nav-item" v-if="learning === true">
        <a
          class="nav-link active"
          id="pills-home-tab"
          data-toggle="pill"
          href="#pills-info"
          role="tab"
          aria-controls="pills-info"
          :aria-selected="{ true: learning === true }"
          >課程資訊</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: learning !== true }"
          id="pills-home-tab"
          data-toggle="pill"
          href="#pills-home"
          role="tab"
          aria-controls="pills-home"
          :aria-selected="{ true: learning === true }"
          >課程介紹</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="pills-profile-tab"
          data-toggle="pill"
          href="#pills-profile"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
          >課程明細</a
        >
      </li>
      <li class="nav-item" v-if="learning !== true">
        <a
          class="nav-link"
          id="pills-contact-tab"
          data-toggle="pill"
          href="#pills-contact"
          role="tab"
          aria-controls="pills-contact"
          aria-selected="false"
          >購物流程</a
        >
      </li>
    </ul>
    <div class="tab-content nav__content" id="pills-tabContent">
      <!-- 課程介紹 -->
      <div
        v-if="learning === true"
        class="tab-pane fade"
        :class="{ 'show active': learning === true }"
        id="pills-info"
        role="tabpanel"
        aria-labelledby="pills-info-tab"
      >
        <div class="p-4 p-md-8">
          <div class="col-12 mb-3">
            <div class="teacher-title">課程名稱</div>
            <p class="px-3">{{ courseInfo.courseName }}</p>
          </div>
          <div class="col-12 mb-3">
            <div class="teacher-title">考試名稱</div>
            <p class="px-3">{{ courseInfo.examName }}</p>
          </div>
          <div class="col-12 mb-3">
            <div class="teacher-title">考試類科</div>
            <p class="px-3">{{ courseInfo.categoryName }}</p>
          </div>
          <div class="col-12 mb-3">
            <div class="teacher-title">班別</div>
            <p class="px-3">{{ courseInfo.roomName }}</p>
          </div>
          <div class="col-12 mb-3">
            <div class="teacher-title">觀看期限</div>
            <p class="px-3">
              {{ courseInfo.courseViewStartDate | toTaipei | moment('YYYY-MM-DD') }} 至
              {{ courseInfo.courseViewEndDate | toTaipei | moment('YYYY-MM-DD') }}
            </p>
          </div>
          <div class="col-12 mb-3">
            <div class="teacher-title">科目</div>
            <p class="px-3">{{ AllSubject }}</p>
          </div>
          <div class="col-12 mb-3">
            <div class="teacher-title">課程類型</div>
            <p class="px-3">{{ CourseTypeName }}</p>
          </div>
          <div class="col-12 mb-3">
            <div class="teacher-title">總堂數</div>
            <p class="px-3">{{ Classes }}堂</p>
          </div>
        </div>
      </div>
      <!-- 課程介紹 -->
      <div
        class="tab-pane fade"
        :class="{ 'show active': learning !== true }"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div class="p-4 p-md-8 edit" v-html="courseInfo.courseContent"></div>
      </div>
      <!-- 課程明細 -->
      <div
        class="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <!-- 精選課程 -->
        <div v-if="courseInfo.classRoomList.length > 0" class="cs-side p-3 p-md-6" data-style="2">
          <div class="course__name"><span>精選課程</span></div>
          <ul class="cs-table bg-light" data-style="9">
            <!-- 標題 -->
            <li class="course__head">
              <div class="course__head-td">科目</div>
              <div class="course__head-td">堂數</div>
              <div class="course__head-td"></div>
            </li>
            <!-- 購買商品項1 -->
            <li
              class="side__box"
              v-for="item in courseInfo.classRoomList"
              :key="`CuresInfo2-${item.subjectId}-${item.teacherId}`"
              @click="ToggleClass($event)"
            >
              <div class="side__title">
                <div class="course__item px-4">
                  <div class="course__item-td course__item--subject">
                    {{ item.groupName }}
                  </div>
                  <div class="course__item-td course__item--class">
                    共 {{ item.buyCourseClassRoomList.length }} 堂
                  </div>
                  <div class="course__item-td course__item--icon">
                    <i class="fa fa-chevron-down icon-icon_arrow mr-3" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <!-- 購買商品介紹 -->
              <div class="side__content cs-table" data-style="10">
                <div class="table__head px-6 px-3">
                  <div class="table__head-td">名稱</div>
                  <div class="table__head-td">講師</div>
                  <div class="table__head-td" v-if="learning === true">補充</div>
                  <div class="table__head-td" v-if="learning === true">筆記</div>
                </div>
                <div
                  class="table__item px-6 px-3"
                  v-for="(item2, index2) in item.buyCourseClassRoomList"
                  :key="`CuresInfo2-option-${item2.classId}-${index2}`"
                >
                  <div class="table__item-td">{{ item2.className }}</div>
                  <div class="table__item-td">
                    {{ item2.teacherName }}
                  </div>
                  <div class="table__item-td" v-if="learning === true">
                    <a
                      v-if="item2.classLecturePath !== ''"
                      :href="item2.classLecturePath"
                      target="_blank"
                      ><i class="fa fa-download" aria-hidden="true"></i
                    ></a>
                  </div>
                  <div class="table__item-td" v-if="learning === true">
                    <a v-if="item2.classNotePath !== ''" :href="item2.classNotePath" target="_blank"
                      ><i class="fa fa-download" aria-hidden="true"></i
                    ></a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 自選課程 -->
        <div v-if="optionalClassInfo.length > 0" class="cs-side p-3 p-md-6" data-style="2">
          <div class="course__name"><span>自選課程</span></div>
          <ul class="cs-table bg-light" data-style="9">
            <!-- 標題 -->
            <li class="course__head">
              <div class="course__head-td">科目</div>
              <div v-if="learning === true" class="course__head-td">堂數</div>
              <div v-if="learning === false" class="course__head-td"></div>
              <div class="course__head-td"></div>
            </li>
            <!-- 購買商品項1 -->
            <li
              class="side__box"
              v-for="item in optionalClassInfo"
              :key="`CuresInfo-${item.subjectId}-${item.teacherId}`"
              @click="ToggleClass($event)"
            >
              <div class="side__title">
                <div class="course__item px-4">
                  <div class="course__item-td course__item--subject">
                    {{ item.groupName }}
                  </div>
                  <div v-if="learning === true"  class="course__item-td course__item--class">
                    共 {{ item.buyCourseClassRoomList.length }} 堂
                  </div>
                  <div v-if="learning === false" class="course__item-td course__item--class">
                  </div>
                  <div class="course__item-td course__item--icon">
                    <i class="fa fa-chevron-down icon-icon_arrow mr-3" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <!-- 購買商品介紹 -->
              <div class="side__content cs-table" data-style="10">
                <div class="table__head px-6 px-3">
                  <div class="table__head-td">名稱</div>
                  <div class="table__head-td">講師</div>
                  <div class="table__head-td" v-if="learning === true">補充</div>
                  <div class="table__head-td" v-if="learning === true">筆記</div>
                </div>
                <div
                  class="table__item px-6 px-3"
                  v-for="(item2, index2) in item.buyCourseClassRoomList"
                  :key="`CuresInfo-option-${item2.classId}-${index2}`"
                >
                  <div class="table__item-td">{{ item2.className }}</div>
                  <div class="table__item-td">
                    {{ item2.teacherName }}
                  </div>
                  <div class="table__item-td" v-if="learning === true">
                    <a
                      v-if="item2.classLecturePath !== ''"
                      :href="item2.classLecturePath"
                      target="_blank"
                      ><i class="fa fa-download" aria-hidden="true"></i
                    ></a>
                  </div>
                  <div class="table__item-td" v-if="learning === true">
                    <a v-if="item2.classNotePath !== ''" :href="item2.classNotePath" target="_blank"
                      ><i class="fa fa-download" aria-hidden="true"></i
                    ></a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 練習題 -->
        <div
          v-if="courseInfo.practiceSubjectList.length > 0"
          class="cs-side p-3 p-md-6" data-style="2">
          <div class="course__name"><span>練習題</span></div>
          <ul class="cs-table bg-light" data-style="9">
            <!-- 標題 -->
            <li class="course__head">
              <div class="course__head-td">科目</div>
              <div class="course__head-td"></div>
            </li>
            <!-- 購買商品項1 -->
            <li
              class="side__box"
              v-for="item in courseInfo.practiceSubjectList"
              :key="`CuresInfo3-${item.subJectId}`"
            >
              <div class="side__title cursor-default">
                <div class="course__item px-4">
                  <div class="course__item-td course__item--subject">
                    {{ item.subJectName }}
                  </div>
                  <div class="course__item-td course__item--icon">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 購物流程 -->
      <div
        class="tab-pane fade"
        id="pills-contact"
        role="tabpanel"
        aria-labelledby="pills-contact-tab"
        v-if="learning !== true"
      >
        <div class="p-4 p-md-8 edit" v-html="noviceContent"></div>
      </div>
    </div>
  </article>
</template>

<script>
import { GetNovicesByMode } from '@/api/Novices';
import { DownloadFileBase64 } from '@/api/BuyCourseApi';
import { convertBase64AndDownload } from '@/lib/File';

export default {
  props: ['courseInfo', 'learning'],
  data() {
    return {
      noviceContent: '',
    };
  },
  created() {
    this.GetNovicesByMode();
  },
  computed: {
    AllSubject() {
      let allSubject = this._.map(this.courseInfo.classRoomList, 'subjectName');
      if (this.courseInfo.courseType === 2) {
        allSubject.push(...this._.flatMap(this.courseInfo.optionalClassRoomList,
          (item) => item.optionalClassRoomList.map((item2) => item2.subjectName)));
        // allSubject.push(...this.courseInfo.optionalClassRoomList
        //   .map((item) => item.optionalSubjectName));
      }
      allSubject = this._.chain(allSubject).uniqBy().join('， ');
      return allSubject;
    },
    CourseTypeName() {
      return `${this.courseInfo.courseTypeName}課程`;
    },
    Classes() {
      let classCount = this._.reduce(
        this.courseInfo.classRoomList,
        (sum, item) => sum + item.buyCourseClassRoomList.length, 0,
      );

      if (this.courseInfo.courseType === 2) {
        classCount += this._.reduce(
          this.optionalClassInfo,
          (sum, item) => sum + item.buyCourseClassRoomList.length, 0,
        );
      }
      return classCount;
    },
    optionalClassInfo() {
      let classInfo = [];
      if (this.courseInfo.courseType === 2) {
        classInfo = this._.chain(this.courseInfo.optionalClassRoomList)
          .map((item) => item.optionalClassRoomList)
          .flatten()
          .value();
      }

      return classInfo;
    },
  },
  methods: {
    ToggleClass(event) {
      if (
        event.target.className === 'side__title'
        || event.target.className.indexOf('course__item') >= 0
        || event.target.className.indexOf('fa-chevron-down') >= 0
      ) event.currentTarget.classList.toggle('active');
    },
    DowloadFile(file) {
      DownloadFileBase64(file).then((response) => {
        if (response.data.statusCode === 0) {
          this.StatusOption = response.data.data;
          this.$message({
            type: 'success',
            message: '請稍後，檔案下載即將開始',
          });
          convertBase64AndDownload(response.data.data.base64, response.data.data.fileName);
        }
      });
    },
    GetNovicesByMode() {
      const modelId = 5;
      GetNovicesByMode(modelId).then((response) => {
        if (response.data.statusCode === 0) {
          this.noviceContent = response.data.data.noviceContent;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.course__name {
  position: relative;
  margin-bottom: 10px;

  >span {
    position: relative;
    font-size: 24px;
    font-weight: 700;
    color: #174e5f;
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(#ffe269,#ffe269) no-repeat left 90%;
    background-size: 100% 8px;
    transform: rotate(-2deg);
    z-index: 1;
  }
}
.cs-side[data-style='2'] .side__title.cursor-default {
  cursor: default;
}
</style>
