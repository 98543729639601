<template>
  <!-- 主要內容 -->
  <section class="buycourse pb-8" v-if="isReedy">
    <div class="container">
      <div class="h3 mb-3">{{ data.courseName }}</div>
      <div class="row">
        <!-- 影片 -->
        <div class="col-12 col-lg-8 mb-3 mb-lg-0">
          <div class="bg-outer rounded border-shadow">
            <div
              class="videoWrapper videoWrapper100 js-videoWrapper videoWrapperActive bg-image"
              :style="{
                backgroundImage: 'url(' + data.contentImagePath + ')',
              }"
            >
              <iframe
                v-if="data.courseYoutubeUrl"
                class="videoIframe js-videoIframe"
                :src="`https://www.youtube.com/embed/${data.courseYoutubeUrl}`"
                frameborder="0"
                allow="accelerometer;
                autoplay;
                clipboard-write;
                encrypted-media;
                gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        <!-- 價格資訊 -->
        <div class="col-12 col-lg-4 cs-price d-flex flex-column justify-content-between">
          <!-- 特價 -->
          <div class="price-title offer my-3 mt-md-0">{{ data.coursePrice | toCurrency }}元</div>
          <div
            class="cs-table d-block justify-self-stretch
            flex-full p-3 mb-auto bg-light border-shadow"
            data-style="7"
          >
            <dl>
              <dt>原價：</dt>
              <dd>
                <span class="origin">{{ data.courseOriPrice | toCurrency }}</span
                >元
              </dd>
            </dl>
            <dl>
              <dt>觀看期限：</dt>
              <dd>
                {{ data.courseViewStartDate | toTaipei | moment('YYYY-MM-DD') }} -
                {{ data.courseViewEndDate | toTaipei | moment('YYYY-MM-DD') }}
              </dd>
            </dl>
            <dl>
              <dt>科目：</dt>
              <dd>{{ AllSubject }}</dd>
            </dl>
            <dl>
              <dt>課程類型：</dt>
              <dd>{{ CourseTypeName }}</dd>
            </dl>
            <dl v-if="this.data.courseType === 1">
              <dt>堂數：</dt>
              <dd>
                <span class="">{{ Classes }}</span
                >堂
              </dd>
            </dl>
          </div>
          <div class="row">
            <div class="col-12">
              <a
                href=""
                class="btn btn-primary w-100 py-2 py-md-3"
                @click.prevent="AddToCar(data.courseId)"
                >加入購物車</a
              >
            </div>
          </div>
        </div>

        <!-- 課程相關資訊 -->
        <CourseInfon :courseInfo="data" :learning="false" />
      </div>
    </div>
  </section>
</template>

<script>
import CourseInfon from '@/components/CourseInfon.vue';
import { GetBuyCourseContent } from '@/api/BuyCourseApi';

export default {
  props: ['currentPage'],
  components: {
    CourseInfon,
  },
  data() {
    // 建立你的初始化 model data
    return {
      isReedy: false,
      data: {
        courseId: 0,
        courseName: '',
        examId: 0,
        examName: '',
        categoryId: 0,
        categoryName: '',
        roomId: 0,
        roomName: '',
        courseOriPrice: 0,
        coursePrice: 0,
        courseContent: '',
        contentImagePath: '',
        courseYoutubeUrl: '',
        courseViewStartDate: this.$moment(new Date('9999/12/31')),
        courseViewEndDate: this.$moment(new Date('9999/12/31')),
        classRoomList: [
          {
            subjectId: 0,
            subjectName: '',
            buyCourseClassRoomList: [
              {
                classId: 0,
                className: '',
                teacherId: 0,
                teacherName: '',
              },
            ],
          },
        ],
      },
      CourseTypeName: '',
      AllSubject: '',
      // optionalClassInfo: [],
      Classes: 0,
    };
  },
  created() {
    this.GetBuyCourseContent();
  },
  methods: {
    GetBuyCourseContent() {
      const { id } = this.$route.params;
      GetBuyCourseContent({ courseId: id }).then((response) => {
        if (response.data.statusCode === 0) {
          try {
            this.data = response.data.data;
            this.$emit('update:currentPage', [
              {
                name: '',
                title: this.data.examName,
                path: '/course',
              },
              {
                name: 'ClassRoom',
                title: this.data.categoryName,
                path: '/course/classRoom',
                params: {
                  examId: this.data.examId,
                  categoryId: this.data.categoryId,
                },
              },
              {
                name: '',
                title: this.data.courseName,
                path: '',
              },
            ]);
            this.Classes = this._.reduce(
              this.data.classRoomList,
              (sum, item) => sum + item.buyCourseClassRoomList.length,
              0,
            );
            this.CourseTypeName = `${this.data.courseTypeName}課程`;
            // 取得 自選課程的科目
            this.AllSubject = this._.map(this.data.classRoomList, 'subjectName');
            if (this.data.courseType === 2) {
              // console.log(
              //   this._.flatMap(this.data.optionalClassRoomList,
              //     (item) => item.optionalClassRoomList.map((item2) => item2.subjectName)),
              // );

              // this.AllSubject.push(...this.data.optionalClassRoomList
              //   .map((item) => item.optionalSubjectName));

              this.AllSubject.push(...this._.flatMap(this.data.optionalClassRoomList,
                (item) => item.optionalClassRoomList.map((item2) => item2.subjectName)));
            }

            this.AllSubject = this._.chain(this.AllSubject).uniqBy().join('， ').value();

            this.isReedy = true;
          } catch (error) {
            this.$router.replace('/error');
          }
        } else this.$router.replace('/error');
      });
    },
    AddToCar(id) {
      this.$bus.$emit('addShoppingCart', id);
    },
  },
};
</script>

<style></style>
